import React, {
  FC,
  useEffect,
} from 'react';
import {
  useLazyGetProviderQuery,
  useUpdateProviderMutation,
} from 'features/provider/api/provider';
import {
  useNavigate,
  useParams,
} from 'react-router';
import PageTopBar from 'features/common/layout/PageTopBar';
import { PageContent } from 'features/common/layout/Styled';
import ProviderForm from 'features/provider/components/ProviderForm';
import { ProviderModel } from 'features/provider/types';

const Provider: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ fetchProvider, provider ] = useLazyGetProviderQuery();
  const [updateProvider, { isSuccess, isLoading }] = useUpdateProviderMutation();

  useEffect(() => {
    if (id) {
      fetchProvider(Number(id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isSuccess) {
    navigate('/providers/import-tasks');
  }

  if (!provider.data) {
    return (<div>Загрузка...</div>);
  }

  const handleSubmit = async (data: ProviderModel) => await updateProvider(data);

  return (
    <>
      <PageTopBar name={`Редактировать поставщика ${provider.data?.name}`} />
      <PageContent>
        <ProviderForm
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onCancel={() => navigate('/providers/list')}
          model={provider.data}
        />
      </PageContent>
    </>
  );
};

export default Provider;
