import React, { FC } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { ProviderModel } from 'features/provider/types';
import { useFormErrors } from 'features/common/hooks';

interface ProviderFormProps {
  onSubmit: (model: ProviderModel) => void;
  onCancel: () => void;
  isLoading: boolean;
  model?: ProviderModel;
}

const getOptionName = (key: string) => {
  switch (key) {
  case 'returns_best_price_for_city': return 'возвращает только лучшую цену при поиске по городу';
  }

  return key;
};

export const initialProviderValues = {
  name: '',
  code: '',
  active: true,
  url: '',
  db_schema: '',
  options: 0,
};

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Введите название'),
  code: yup
    .string()
    .required('Введите код'),
  db_schema: yup
    .string()
    .required('Введите схему БД'),
});

const ProviderForm: FC<ProviderFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  model,
}) => {
  const initialValues = model ? { ...model } : {
    name: '',
    code: '',
    active: true,
    url: '',
    db_schema: '',
    options: {
      returns_best_price_for_city: false,
    },
  };

  const formik = useFormik<ProviderModel>({
    initialValues,
    validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  useFormErrors(formik.setErrors);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name"
            label="Название"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="code"
            label="Код"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="url"
            label="GRPC URL"
            value={formik.values.url}
            onChange={formik.handleChange}
            error={formik.touched.url && Boolean(formik.errors.url)}
            helperText={formik.touched.url && formik.errors.url}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="db_schema"
            label="Схема в базе данных"
            value={formik.values.db_schema}
            onChange={formik.handleChange}
            error={formik.touched.db_schema && Boolean(formik.errors.db_schema)}
            helperText={formik.touched.db_schema && formik.errors.db_schema}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch checked={formik.values.active} onChange={formik.handleChange} name="active" />
            }
            label="Активен"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Опции</FormLabel>
            <FormGroup>
              {Object.keys(formik.values.options).map((key, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Switch
                      checked={formik.values.options.returns_best_price_for_city}
                      onChange={formik.handleChange}
                      name="options[returns_best_price_for_city]"
                    />
                  }
                  label={getOptionName(key)}
                />
              ))}
            </FormGroup>
            <FormHelperText error={formik.touched.options && Boolean(formik.errors.options)}>
              {formik.touched.code && formik.errors.code}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} marginTop={2}>
        <LoadingButton
          color="success"
          loadingPosition="start"
          variant="contained"
          type="submit"
          startIcon={<SaveIcon />}
          loading={isLoading}
        >
          {model ? 'Редактировать' : 'Создать'}
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          Вернуться назад
        </Button>
      </Stack>
    </Box>
  );
};

export default ProviderForm;
