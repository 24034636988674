import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import * as yup from 'yup';
import {
  FormikErrors,
  FormikTouched,
  useFormik,
} from 'formik';
import {
  LoadingButton,
  TimePicker,
} from '@mui/lab';
import {
  HotelModel,
  HotelRequest,
  ProviderMapping,
  ProviderMappingRequest,
  CityModel,
  FacilityModel,
  HotelFacilitiesRequest,
  HotelType,
  PaymentSystems,
} from 'features/dictionary/types';
import { useFormErrors } from 'features/common/hooks';
import FormAutocomplete from 'features/common/components/FormAutocomplete';
import { ProviderModel } from 'features/provider/types';
import { useLazySearchProvidersQuery } from 'features/provider/api/provider';
import { useLazySearchCitiesQuery } from 'features/dictionary/api/city';
import CustomMap from 'features/common/components/CustomMap';
import { showPopupMessage } from 'features/common/slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';
import { DateTime } from 'luxon';
import HotelPhotos from 'features/dictionary/components/HotelPhotos';
import { useUploadHotelPhotosMutation } from 'features/dictionary/api/hotelPhoto';
import { useListFacilitiesQuery } from 'features/dictionary/api/facility';
import { Waypoint } from 'react-waypoint';
import FormFieldPhotos from 'features/common/components/FormFieldPhotos';
import HotelRooms from 'features/dictionary/components/HotelRooms';
import HotelOptions from 'features/dictionary/components/HotelOptions';

interface HotelFormProps {
  onSuccess: (model: HotelRequest, facilities?: HotelFacilitiesRequest) => void;
  onCancel: () => void;
  isLoading: boolean;
  model?: HotelModel;
  facilities?: FacilityModel[];
  hotelTypes: HotelType[];
}

const validationSchema = yup.object({
  mappings: yup.array().of(yup.object({
    provider_id: yup.number().required('Выберите поставщика'),
    provider_code: yup.string().required('Введите код'),
  })),
  name_ru: yup
    .string()
    .required('Введите название'),
  name_en: yup
    .string()
    .required('Введите название'),
  address_ru: yup
    .string()
    .required('Введите адрес'),
  city_id: yup
    .number()
    .min(1, 'Выберите город')
    .required('Выберите город'),
  category: yup
    .number()
    .min(1, 'Категория должна быть числом от 1')
    .max(7, 'Категория должна быть числом до 7')
    .required('Введите категорию'),
  description: yup.object({
    check_in_time: yup
      .date()
      .typeError('Неправильный формат времени, введите в 24-часовом  формате "часы:минуты"')
      .required('Выберите время заезда'),
    check_out_time: yup
      .date()
      .typeError('Неправильный формат времени, введите в 24-часовом формате "часы:минуты"')
      .required('Выберите время выезда'),
    description_ru: yup
      .string()
      .required('Введите описание'),
    email: yup
      .string()
      .nullable()
      .email('Неправильные email'),
    type_id: yup
      .number(),
  }),
});

const paymentSystems = [
  {
    value: 'mastercard',
    label: 'MasterCard',
  },
  {
    value: 'visa',
    label: 'Visa',
  },
  {
    value: 'mir',
    label: 'МИР',
  },
  {
    value: 'jcb',
    label: 'JCB',
  },
  {
    value: 'cup',
    label: 'UnionPay',
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const HotelForm: FC<HotelFormProps> = ({
  hotelTypes,
  onSuccess,
  onCancel,
  isLoading,
  model,
  facilities,
}) => {
  const [ fetchTaskProviders, providers ] = useLazySearchProvidersQuery();
  const [ fetchCities, cities ] = useLazySearchCitiesQuery();
  const [ uploadPhotos ] = useUploadHotelPhotosMutation();
  const { data: facilityList, isLoading: isLoadingFacilities } = useListFacilitiesQuery({ sort: 'fac.name_ru', limit: 1000, page: 1 });
  const [selectedFacilities, setSelectedFacilities] = React.useState<number[]>([]);
  const mappingsRef = useRef<HTMLDivElement>(null);
  const photosRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const roomsRef = useRef<HTMLDivElement>(null);
  const facilitiesRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [ visibleMap, setVisibleMap ] = useState({
    mappings: false,
    photos: false,
    card: false,
    rooms: false,
    facilities: false,
    options: false,
  });
  const dispatch: AppDispatch = useDispatch();

  const initialValues = model ? {
    address_en: model.address_en,
    address_ru: model.address_ru,
    coordinates: {
      longitude: model.coordinates.longitude,
      latitude: model.coordinates.latitude,
    },
    category: model.category,
    city_id: model.city_id,
    type_id: model.type_id,
    name_en: model.name_en,
    name_ru: model.name_ru,
    description: {
      ...model.description,
      check_in_time: model.description.check_in_time !== null ? DateTime.fromFormat(model.description.check_in_time as string, 'HH:mm') : '',
      check_out_time: model.description.check_out_time !== null ? DateTime.fromFormat(model.description.check_out_time as string, 'HH:mm') : '',
    },
    mappings: model.mappings,
    options: model.options,
    payment_systems: model.payment_systems,
  } : {
    address_en: '',
    address_ru: '',
    coordinates: {
      longitude: null,
      latitude: null,
    },
    category: 0,
    city_id: 0,
    type_id: 0,
    description: {
      check_in_time: DateTime.now().set({ hour: 0, minute: 0 }),
      check_out_time: DateTime.now().set({ hour: 0, minute: 0 }),
      description_en: '',
      description_ru: '',
      email: '',
      phone: '',
      website: '',
    },
    name_en: '',
    name_ru: '',
    mappings: [] as ProviderMapping[],
    options: 0,
    payment_systems: [],
  };

  const [mapCenter, setMapCenter] = useState([37.6155600, 55.7522200]);
  const [mapMarker, setMapMarker] = useState([initialValues.coordinates.longitude, initialValues.coordinates.latitude]);
  const [city, setCity] = useState(model?.city.name_ru);

  const handleCoordinates = (lngLat: number[], hideMessage?: boolean) => {
    if (!lngLat[0] || !lngLat[1]) {
      return;
    }
    setMapMarker(lngLat);
    setMapCenter(lngLat);
    formik.setFieldValue('coordinates.longitude', lngLat[0]);
    formik.setFieldValue('coordinates.latitude', lngLat[1]);
    if(!hideMessage) {
      dispatch(showPopupMessage({ text: 'Маркер был установлен на карте!' }));
    }
  };

  const handleMapCenter = async(address: string, isSetMarker?: boolean) => {
    const res = await fetch(`https://catalog.api.2gis.com/3.0/items/geocode?q=${address}&fields=items.point,items.geometry.centroid&key=rurfey8431`);
    const location = await res.json();
    if (!location?.result) {
      return;
    }
    const coordinates = [location.result.items[0].point.lon, location.result.items[0].point.lat];
    setMapCenter(coordinates);
    if (isSetMarker) {
      handleCoordinates(coordinates);
    }
  };

  const handleCoordinatesByAddress = (address: string) => {
    if (!address || !city) {
      return;
    }
    handleMapCenter(`${city}, ${address}`, true);
  };

  useEffect(() => {
    fetchTaskProviders('');
    fetchCities('');

    if (initialValues.coordinates.longitude && initialValues.coordinates.latitude) {
      setMapCenter([initialValues.coordinates.longitude, initialValues.coordinates.latitude]);
    } else {
      const address = `${model?.city.name_ru as string}, ${initialValues.address_ru as string}`;
      handleMapCenter(address);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (facilities && facilityList?.data) {
      setSelectedFacilities(facilities.map(item => Number(item.id)));
    }
  }, [facilities, facilityList?.data]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const mappings = values.mappings.reduce<ProviderMappingRequest>((ret, value) => {
        ret[value.provider_id] = value.provider_code;

        return ret;
      }, {});

      const request = {
        ...values,
        mappings,
      };

      if (request.description.check_in_time instanceof DateTime) {
        (request.description.check_in_time as unknown) = request.description.check_in_time.toFormat('HH:mm');
      }
      if (request.description.check_out_time instanceof DateTime) {
        (request.description.check_out_time as unknown) = request.description.check_out_time.toFormat('HH:mm');
      }

      model ? onSuccess(request, { facilities: selectedFacilities }) : onSuccess(request);
    },
  });

  useFormErrors(formik.setErrors);

  // Scroll to error on submit
  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]');
    (el?.parentElement ?? el)?.scrollIntoView();
  }, [formik?.isSubmitting]);

  const handleProviderAdd = () => {
    const provs = getAvailableProviders();
    if (provs.length === 0) {
      return;
    }

    formik.setValues(values => ({
      ...values,
      mappings: [
        ...values.mappings,
        {
          provider_id: Number(provs[0].id),
          provider_code: '',
        },
      ],
    }));
  };

  const onPhotosSelected = (formData: FormData) => {
    uploadPhotos({ hotelId: model?.id as number, formData });
  };

  const handleRemoveMapping = (key: number) => () => {
    formik.setValues(values => ({
      ...values,
      mappings: values.mappings.filter((_, idx) => idx !== key),
    }));
  };

  const getAvailableProviders = useCallback((id?: number) => providers.data?.filter(item => item.id === id
      || !formik.values.mappings.find(val => val.provider_id === item.id)
  ) || [], [formik.values.mappings, providers.data]);

  const handleChange = (event: SelectChangeEvent<typeof selectedFacilities>) => {
    const { target: { value } } = event;
    Array.isArray(value) && setSelectedFacilities(value);
  };

  const handleOptionsChange = (options: number) => {
    formik.setValues(values => ({
      ...values,
      options,
    }));
  };

  const mappingsEl = useMemo(() => (formik.values.mappings as ProviderMapping[]).map((mapping, idx) => (
    <Fragment key={mapping.provider_id}>
      <Grid item xs={12} sm={5}>
        <FormAutocomplete<ProviderModel>
          field={`mappings[${idx}].provider_id`}
          label="Поставщик"
          isFetching={providers.isLoading}
          list={getAvailableProviders(mapping.provider_id)}
          selectedOption={providers.data?.find(item => item.id === mapping.provider_id)}
          disableClearable={true}
          error={false}
          helperText=""
          filterOptions={createFilterOptions<ProviderModel>({
            stringify: ({ name }) => name,
          })}
          comparator={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.name}
            </Box>
          )}
          handleChange={(event, value) => formik.setFieldValue(`mappings[${idx}].provider_id`, value ? value.id : 0)}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          id={`mappings[${idx}].provider_code`}
          label="Код поставщика"
          value={formik.values.mappings[idx].provider_code}
          onChange={formik.handleChange}
          error={(formik.touched.mappings?.at(idx) as FormikTouched<ProviderMapping>)?.provider_code
            && Boolean((formik.errors.mappings?.at(idx) as FormikErrors<ProviderMapping>)?.provider_code)}
          helperText={(formik.touched.mappings?.at(idx) as FormikTouched<ProviderMapping>)?.provider_code
            && (formik.errors.mappings?.at(idx) as FormikErrors<ProviderMapping>)?.provider_code}
        />
      </Grid>
      <Grid item xs={12} sm={2} display="flex" alignItems="center">
        <Button onClick={handleRemoveMapping(idx)}>Удалить</Button>
      </Grid>
    </Fragment>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  )), [getAvailableProviders, handleRemoveMapping, providers.data, providers.isLoading]);

  const handleElementEnter = (name: string) => setVisibleMap(prev => ({ ...prev, [name]: true }));
  const handleElementLeave = (name: string) => setVisibleMap(prev => ({ ...prev, [name]: false }));

  const getCurrentWaypoint = () => {
    if (visibleMap.mappings) {
      return 0;
    }

    if (visibleMap.photos) {
      return 1;
    }

    if (visibleMap.options) {
      return 2;
    }

    if (visibleMap.facilities) {
      return 3;
    }

    if (visibleMap.rooms) {
      return 4;
    }

    if (visibleMap.card) {
      return 5;
    }

    return 0;
  };
  return (
    <Stack
      direction="row"
      flex={1}
      height="inherit"
    >
      <Tabs
        orientation="vertical"
        value={getCurrentWaypoint()}
        variant="scrollable"
        sx={{ m: '0 16px 16px 16px', borderColor: 'divider' }}
      >
        <Tab label="Маппинги" value={0} onClick={() => mappingsRef.current?.scrollIntoView() } />
        <Tab label="Фотографии" value={1} onClick={() => photosRef.current?.scrollIntoView() } disabled={model === undefined} />
        <Tab label="Опции" value={2} onClick={() => optionsRef.current?.scrollIntoView() } disabled={model === undefined} />
        <Tab label="Удобства" value={3} onClick={() => facilitiesRef.current?.scrollIntoView() } />
        <Tab label="Комнаты отеля" value={4} onClick={() => roomsRef.current?.scrollIntoView() } />
        <Tab label="Карточка отеля" value={5} onClick={() => cardRef.current?.scrollIntoView() } />
      </Tabs>
      <Stack flex={1} display="flex" flexDirection="column">
        <Box component="form" ref={formRef} sx={{ pr: '16px', overflowY: 'auto', height: 'calc(85vh)' }} onSubmit={formik.handleSubmit}>
          <>
            <Waypoint
              onEnter={() => handleElementEnter('mappings')}
              onLeave={() => handleElementLeave('mappings')}
            >
              <div ref={mappingsRef}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" gutterBottom paddingBottom={3}>
                    Коды поставщиков
                  </Typography>
                  <Button
                    size="medium"
                    disabled={getAvailableProviders().length === 0}
                    sx={{ height: '50%' }}
                    variant="contained"
                    onClick={handleProviderAdd}
                  >
                    Добавить
                  </Button>
                </Stack>
                <Grid container spacing={2}>
                  {mappingsEl}
                </Grid>
              </div>
            </Waypoint>
            {model && (
              <>
                <Waypoint
                  onEnter={() => handleElementEnter('photos')}
                  onLeave={() => handleElementLeave('photos')}
                >
                  <Box ref={photosRef} marginTop="10px">
                    <FormFieldPhotos htmlFor={'hotelPhoto-uploader'} onChange={onPhotosSelected} />
                    {
                      model && <HotelPhotos
                        mainPhoto={model.photo}
                        hotelId={model.id as number}
                      />
                    }
                  </Box>
                </Waypoint>
                <Waypoint
                  onEnter={() => handleElementEnter('options')}
                  onLeave={() => handleElementLeave('options')}
                >
                  <Box ref={optionsRef} marginTop="10px">
                    <Typography variant="h6" gutterBottom>
                      Опции
                    </Typography>
                    <HotelOptions options={formik.values.options} onChange={handleOptionsChange} />
                  </Box>
                </Waypoint>
                <Waypoint
                  onEnter={() => handleElementEnter('facilities')}
                  onLeave={() => handleElementLeave('facilities')}
                >
                  <Box ref={facilitiesRef} marginTop="10px">
                    <Typography variant="h6" gutterBottom paddingY={2}>
                      Удобства
                    </Typography>
                    <Grid item xs={12} sm={6}>
                      {isLoadingFacilities ? <CircularProgress /> : (
                        <FormControl fullWidth={true}>
                          <InputLabel id="facilities-multiple-checkbox-label">Список удобств</InputLabel>
                          <Select
                            labelId="facilities-multiple-checkbox-label"
                            id="facilities-multiple-checkbox-label"
                            multiple
                            value={selectedFacilities}
                            onChange={handleChange}
                            input={<OutlinedInput label="Список удобств" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => {
                                  const res = facilityList?.data.find(item => Number(item.id) === value);
                                  return <Chip key={value} sx={{ fontSize: '1rem' }} label={res ? res.name_ru : 'не найдено'} />;
                                })}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {facilityList && facilityList.data.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={selectedFacilities.indexOf(Number(item.id)) > -1} />
                                <ListItemText primary={item.name_ru} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  </Box>
                </Waypoint>
              </>
            )}
            {model && (
              <Waypoint
                onEnter={() => handleElementEnter('rooms')}
                onLeave={() => handleElementLeave('rooms')}
              >
                <Box ref={roomsRef} marginTop="10px">
                  <HotelRooms
                    hotelId={model.id as number}
                  />
                </Box>
              </Waypoint>
            )}
            <Waypoint
              onEnter={() => handleElementEnter('card')}
              onLeave={() => handleElementLeave('card')}
            >
              <Box ref={cardRef} marginTop="10px">
                <Typography variant="h6" gutterBottom paddingY={2}>
                Переводы названий
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="name_ru"
                      label="Название на русском"
                      value={formik.values.name_ru}
                      onChange={formik.handleChange}
                      error={formik.touched.name_ru && Boolean(formik.errors.name_ru)}
                      helperText={formik.touched.name_ru && formik.errors.name_ru}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="name_en"
                      label="Название на английском"
                      value={formik.values.name_en}
                      onChange={formik.handleChange}
                      error={formik.touched.name_en && Boolean(formik.errors.name_en)}
                      helperText={formik.touched.name_en && formik.errors.name_en}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="type_id"
                      name="type_id"
                      label="Тип отеля"
                      select={true}
                      value={formik.values.type_id}
                      onChange={formik.handleChange}
                      error={formik.touched.type_id && Boolean(formik.errors.type_id)}
                      helperText={formik.touched.type_id && formik.errors.type_id}
                    >
                      <MenuItem key={0} value={0}>
                        Не выбран
                      </MenuItem>
                      {hotelTypes.map((hotelType) => (
                        <MenuItem key={hotelType.id} value={hotelType.id}>
                          {hotelType.name_ru}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="category"
                      label="Категория"
                      type="number"
                      value={formik.values.category}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      onChange={formik.handleChange}
                      error={formik.touched.category && Boolean(formik.errors.category)}
                      helperText={formik.touched.category && formik.errors.category}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      label="Время заезда"
                      inputFormat="HH:mm"
                      ampm={false}
                      mask="__:__"
                      value={formik.values.description.check_in_time}
                      onChange={e => formik.setFieldValue('description.check_in_time', e !== null ? e : '')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          name="description.check_in_time"
                          error={formik.touched.description?.check_in_time && Boolean(formik.errors.description?.check_in_time)}
                          helperText={formik.touched.description?.check_in_time && formik.errors.description?.check_in_time as string}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TimePicker
                      label="Время выезда"
                      inputFormat="HH:mm"
                      ampm={false}
                      mask="__:__"
                      value={formik.values.description.check_out_time}
                      onChange={e => {formik.setFieldValue('description.check_out_time', e !== null ? e : '');}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          name="description.check_out_time"
                          error={formik.touched.description?.check_out_time && Boolean(formik.errors.description?.check_out_time)}
                          helperText={formik.touched.description?.check_out_time && formik.errors.description?.check_out_time as string}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth={true}>
                      <InputLabel id="paymentSystems-multiple-checkbox-label">Список платежных систем</InputLabel>
                      <Select
                        labelId="paymentSystems-multiple-checkbox-label"
                        id="paymentSystems-multiple-checkbox"
                        name="payment_systems"
                        multiple
                        value={formik.values.payment_systems}
                        onChange={formik.handleChange}
                        input={<OutlinedInput label="Список платежных систем" />}
                        renderValue={(selected: string[]) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                              const res = paymentSystems.find(item => item.value === value);
                              return <Chip key={value} sx={{ fontSize: '1rem' }} label={res ? res.label : 'не найдено'} />;
                            })}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {paymentSystems.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            <Checkbox checked={formik.values.payment_systems.indexOf(item.value as PaymentSystems) > -1} />
                            <ListItemText primary={item.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography variant="h6" gutterBottom paddingY={2}>
                  Описание
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="description.description_ru"
                      label="Описание на русском"
                      value={formik.values.description.description_ru ? formik.values.description.description_ru : ''}
                      onChange={formik.handleChange}
                      error={formik.touched.description?.description_ru && Boolean(formik.errors.description?.description_ru)}
                      helperText={formik.touched.description?.description_ru && formik.errors.description?.description_ru}
                      multiline
                      minRows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="description.description_en"
                      label="Описание на английском"
                      value={formik.values.description.description_en ? formik.values.description.description_en : ''}
                      onChange={formik.handleChange}
                      error={formik.touched.description?.description_en && Boolean(formik.errors.description?.description_en)}
                      helperText={formik.touched.description?.description_en && formik.errors.description?.description_en}
                      multiline
                      minRows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="description.email"
                      label="Email"
                      value={formik.values.description.email ? formik.values.description.email : ''}
                      onChange={formik.handleChange}
                      error={formik.touched.description?.email && Boolean(formik.errors.description?.email)}
                      helperText={formik.touched.description?.email && formik.errors.description?.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="description.phone"
                      label="Телефон"
                      value={formik.values.description.phone ? formik.values.description.phone : ''}
                      onChange={formik.handleChange}
                      error={formik.touched.description?.phone && Boolean(formik.errors.description?.phone)}
                      helperText={formik.touched.description?.phone && formik.errors.description?.phone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="description.website"
                      label="Сайт"
                      value={formik.values.description.website ? formik.values.description.website : ''}
                      onChange={formik.handleChange}
                      error={formik.touched.description?.website && Boolean(formik.errors.description?.website)}
                      helperText={formik.touched.description?.website && formik.errors.description?.website}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h6" gutterBottom paddingY={2}>
               Местонахождение
                </Typography>
                <Stack marginBottom={2}>
                  <CustomMap
                    mapCenter={mapCenter}
                    markerCoordinates={mapMarker}
                    zoom={13}
                    onEditMarker={(value) => handleCoordinates(value, true)}
                  />
                </Stack>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="address_ru"
                      label="Адрес на русском"
                      value={formik.values.address_ru ? formik.values.address_ru : ''}
                      onChange={formik.handleChange}
                      onBlur={() => handleCoordinatesByAddress(formik.values.address_ru)}
                      error={formik.touched.address_ru && Boolean(formik.errors.address_ru)}
                      helperText={formik.touched.address_ru && formik.errors.address_ru}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="address_en"
                      label="Адрес на английском"
                      value={formik.values.address_en ? formik.values.address_en : ''}
                      onChange={formik.handleChange}
                      error={formik.touched.address_en && Boolean(formik.errors.address_en)}
                      helperText={formik.touched.address_en && formik.errors.address_en}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="coordinates.longitude"
                      label="Долгота"
                      value={formik.values.coordinates.longitude ? formik.values.coordinates.longitude : ''}
                      onChange={formik.handleChange}
                      onBlur={() => handleCoordinates([
                      formik.values.coordinates.longitude as number,
                      formik.values.coordinates.latitude as number,
                      ])}
                      error={formik.touched.coordinates?.longitude && Boolean(formik.errors.coordinates?.longitude)}
                      helperText={formik.touched.coordinates?.longitude && formik.errors.coordinates?.longitude}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="coordinates.latitude"
                      label="Широта"
                      value={formik.values.coordinates.latitude ? formik.values.coordinates.latitude : ''}
                      onChange={formik.handleChange}
                      onBlur={() => handleCoordinates([
                      formik.values.coordinates.longitude as number,
                      formik.values.coordinates.latitude as number,
                      ])}
                      error={formik.touched.coordinates?.latitude && Boolean(formik.errors.coordinates?.latitude)}
                      helperText={formik.touched.coordinates?.latitude && formik.errors.coordinates?.latitude}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormAutocomplete<CityModel>
                      field="city_id"
                      label="Введите город"
                      isFetching={cities.isLoading}
                      fetchList={fetchCities}
                      list={cities.data || []}
                      error={formik.touched.city_id && Boolean(formik.errors.city_id)}
                      helperText={formik.touched.city_id && formik.errors.city_id}
                      selectedOption={model?.city}
                      filterOptions={createFilterOptions<CityModel>({
                        stringify: ({ name_ru, name_en }) => `${name_ru} ${name_en}`,
                      })}
                      comparator={(option, value) => (value.name_ru !== '' && option.name_ru === value.name_ru) || (value.name_en !== '' && option.name_en === value.name_en)}
                      getOptionLabel={(option) => option.name_ru}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                          {`${option.name_ru} ${option.name_en}`}
                        </Box>
                      )}
                      handleChange={(event, value) => {
                        formik.setFieldValue('city_id', value ? value.id : 0);
                        setCity(value?.name_ru as string);
                        handleMapCenter(value?.name_ru as string);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Waypoint>
          </>
        </Box>
        <Stack direction="row" spacing={2} paddingTop={2}>
          <LoadingButton
            color="success"
            loadingPosition="start"
            variant="contained"
            onClick={() => formik.submitForm()}
            startIcon={<SaveIcon />}
            loading={isLoading}
          >
            {model ? 'Редактировать' : 'Создать'}
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={onCancel}
          >
            Вернуться назад
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HotelForm;
