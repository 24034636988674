import {
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import React, { FC } from 'react';

interface HotelOptionsProps {
  options: number;
  onChange: (value: number) => void;
}

const OptionsValues = [
  'Оплата в отеле с картой',
  'Оплата в отеле без карты',
];

const HotelOptions: FC<HotelOptionsProps> = ({
  options,
  onChange,
}) => (
  <FormGroup row={true}>
    {OptionsValues.map((option, index) => (
      <FormControlLabel
        label={option}
        key={index}
        control={
          <Checkbox
            checked={Boolean(options & (index + 1))}
            onChange={() => onChange(options ^ (index + 1))}
          />
        }
      />
    ))}
  </FormGroup>
);

export default HotelOptions;
